import {Tab as MuiTab, Tabs as MuiTabs, TabProps} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

export const Tabs = withStyles((theme: Theme) =>
  createStyles({
    root: {
      alignSelf: 'flex-end',
    },
    indicator: {
      backgroundColor: theme.palette.primary.main,
    },
  })
)(MuiTabs);

export const Tab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      margin: theme.spacing(0, 2),
      '&:hover': {
        color: '#1c252c',
        opacity: 1,
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    selected: {},
  })
)((props: TabProps) => <MuiTab disableRipple {...props} />);
