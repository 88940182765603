import {yupResolver} from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import {CalendarPicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
} from '@mui/material';
import {useNotification} from '@ozark/common';
import {addDays} from 'date-fns';
import {useEffect, useState} from 'react';
import {Controller, SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import * as yup from 'yup';

interface Props {
  eligibleReleaseDate: Date | undefined | null;
  handleSave: (date: Date | null) => void;
  allowEdit: boolean;
}

type FormData = {
  eligibleReleaseDate: Date;
};

const schema = yup.object().shape({
  eligibleReleaseDate: yup.date().typeError('Invalid date'),
});

export const EligibleReleaseDateEditor = ({handleSave, allowEdit, eligibleReleaseDate}: Props) => {
  const [open, setOpen] = useState(false);
  const showNotification = useNotification();

  const {control, handleSubmit, reset} = useForm<FormData>({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  useEffect(() => {
    reset({
      eligibleReleaseDate: eligibleReleaseDate ? eligibleReleaseDate : addDays(new Date(), 90),
    });
  }, [reset, eligibleReleaseDate]);

  const handleClear = () => {
    handleSave(null);
  };

  const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
    handleSave(data.eligibleReleaseDate);
    setOpen(false);
  };
  const onError: SubmitErrorHandler<FormData> = (errors: FieldErrors<FormData>) => {
    showNotification('error', 'Failed to save Eligible Release Date.');
    console.log(errors);
  };
  const onCancel = () => {
    setOpen(false);
  };
  return (
    <>
      {allowEdit && (
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
          edge="end"
        >
          <EditIcon color="primary" />
        </IconButton>
      )}
      {allowEdit && (
        <IconButton onClick={handleClear} edge="end" disabled={!eligibleReleaseDate}>
          <DeleteIcon color={eligibleReleaseDate ? 'primary' : 'disabled'} />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Reserve Eligible Release Date</DialogTitle>
        <DialogContent>
          <Controller
            name="eligibleReleaseDate"
            control={control}
            render={({field}) => (
              <FormControl fullWidth variant="standard" required>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <CalendarPicker {...field} date={field.value} disablePast />
                </LocalizationProvider>
              </FormControl>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Box>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={handleSubmit(onSubmit, onError)}>Save</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
