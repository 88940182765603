import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box, {BoxProps} from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import MuiTextField from '@mui/material/TextField';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {
  AdminContact,
  Agent,
  AgentOffice,
  AgentStatus,
  AgentView,
  copyWithoutRef,
  GroupRole,
  PayBy,
  PayTo,
  StateArray,
  StateHash,
  UnitedStatesTimeZonesShortList,
  useAgents,
  useAgentsWithScheduleA,
  useCallable,
  useGroups,
  useUserInfo,
} from '@ozark/common';
import {AutoCompleteAddressTextField, Loading, Select, TextField} from '@ozark/common/components';
import {emptyStringToNull, trimWhiteSpace} from '@ozark/common/helpers';
import {
  AgentPermissions,
  SplitMethodType,
  UniversalTimestamp,
} from '@ozark/functions/src/documents';
import React, {FC, useEffect, useState} from 'react';
import {Controller, useFieldArray, UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {getAgentPhoneSchema} from './phoneValidator';

const getAgentLabel = (agent: AgentView, withoutGroupName?: boolean) =>
  withoutGroupName
    ? `${agent.firstName} ${agent.lastName}`
    : `${agent.firstName} ${agent.lastName} (${agent.group?.name ?? '-'})`;

export interface AgentFormModel {
  groupId: string;
  role: GroupRole;
  masterUid?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  dateOfBirth: UniversalTimestamp;
  office: AgentOffice;
  adminContact: AdminContact;
  timeZoneId: string;
  agentStatus: AgentStatus;
  agentCode: string;
  subAgentCode?: string;
  permissions?: AgentPermissions;
  splitAdditionalServiceFee?: boolean;
  siteJumpAgents?: {id: string; fullName: string; groupName: string}[];
  routingNumber?: string;
  accountNumber?: string;
  payTo?: PayTo;
  payBy?: PayBy;
  uwNotifyMerchant?: boolean;
  isVip?: boolean;
  disableOnlineApplications?: boolean;
  isHierarchyLocked?: boolean;
  agentResidualShares?: {
    agentId: string;
    agentName: string;
    displayedResiduals: number;
    calculatedResiduals: number;
  }[];
}

export const Schema = yup.object().shape(
  {
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    groupId: yup.string().required('Group is required'),
    email: yup.string().email().required('Email is required'),
    role: yup.string().required('Role is required'),
    masterUid: yup.string(),
    phone: getAgentPhoneSchema(),
    dateOfBirth: yup.date().nullable().transform(emptyStringToNull).typeError('Invalid date'),
    agentStatus: yup.string().when(['editMode'], {
      is: (editMode: boolean) => editMode,
      then: yup.string().transform(emptyStringToNull).required('Agent Status is required'),
    }),
    adminContact: yup.object().shape({
      email: yup.string().email('Invalid email'),
    }),
    agentCode: yup.string().transform(trimWhiteSpace).required('Agent Code is required'),
    subAgentCode: yup.string().when(['masterUid'], {
      is: (masterUid: string) => !!masterUid,
      then: yup.string().required('Sub-Agent Code is required'),
      otherwise: yup.string().transform(emptyStringToNull).nullable(), // remove agent code validation of no masterUid
    }),
    uwNotifyMerchant: yup.boolean().nullable(),
    permissions: yup.object().shape({
      residuals: yup.boolean(),
    }),
    disableOnlineApplications: yup.boolean().nullable(),
    isHierarchyLocked: yup.boolean(),
    splitAdditionalServiceFee: yup.boolean(),
    siteJumpAgents: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          fullName: yup.string(),
          groupName: yup.string(),
        })
      )
      .optional(),
    isVip: yup.boolean().nullable(),
    agentResidualShares: yup.array().of(
      yup.object().shape({
        agentId: yup.string().transform(emptyStringToNull).nullable().required('Agent is required'),
        agentName: yup
          .string()
          .transform(emptyStringToNull)
          .nullable()
          .required('Agent is required'),
        displayedResiduals: yup
          .number()
          .min(0, 'Minimum 0')
          .required('Displayed Residuals is required'),
        calculatedResiduals: yup
          .number()
          .min(0, 'Minimum 0')
          .required('Calculated Residuals is required'),
      })
    ),
  },
  [['phone', 'phone']]
);

const StyledTitleWrapper: FC<BoxProps> = props => <Box sx={{marginTop: 2}} {...props} />;

const StyledTitle: FC<TypographyProps> = props => (
  <Typography gutterBottom sx={{fontSize: '1.2em'}} variant="caption" {...props} />
);

export const AgentForm = ({
  hookForm,
  isAuthUserAdmin,
  editMode = false,
  agent,
}: {
  hookForm: UseFormReturn<AgentFormModel, any>;
  isAuthUserAdmin: boolean;
  editMode?: boolean;
  agent?: Agent;
}) => {
  const {
    formState: {errors},
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = hookForm;

  const userInfo = useUserInfo();

  const isAllowedEditShares = userInfo.isAgentAdmin || userInfo.isErpAdmin;
  const isCalculatedSharesVisible = !userInfo.isAgentMember;

  const {documents: groups} = useGroups();
  const {getUniqueAgentCodes} = useCallable();

  const {
    documents: {data: agents},
  } = useAgents();

  const [agentStatus, setAgentStatus] = useState<AgentStatus>(
    agent?.isActive
      ? AgentStatus.active
      : agent?.isDisabled
      ? AgentStatus.disabled
      : AgentStatus.inactive
  );

  const {agentsWithScheduleA} = useAgentsWithScheduleA(agents, groups.data);

  const watchGroupId = watch('groupId');

  const filteredAgents = watchGroupId
    ? agents?.filter(e => e.group?.id === watchGroupId && e.id !== agent?.id)
    : [];

  const selectedGroupPayTo =
    groups.data?.find(x => x.id === watchGroupId)?.applicationSettings?.payTo ?? PayTo.payToGroup;

  const isGroupSplitAdditionalServiceFeeDisabled = !groups.data?.find(x => x.id === watchGroupId)
    ?.applicationSettings?.splitAdditionalServiceFee;

  const siteJumpAgents = watch('siteJumpAgents') ?? [];
  const watchMasterUid = watch('masterUid');

  const handleGroupChangeSuccess = async (value: string) => {
    if (value) {
      setValue('masterUid', '');
    }
    const getUniqueAgentCodesResult = await getUniqueAgentCodes({groupId: value});
    if (getUniqueAgentCodesResult.status === 'error') {
      setValue('agentCode', '');
      return;
    }
    setValue('agentCode', getUniqueAgentCodesResult.agentCode);
  };

  const handleMasterUidChangeSuccess = async (value: string) => {
    const masterAgent = value ? filteredAgents?.find(e => e.id === value) : null;
    if (value) {
      setValue('agentCode', masterAgent?.agentCode || '');
      let subAgentCode = agent?.subAgentCode;
      if (agents && (agent?.masterUid !== value || !subAgentCode)) {
        const getUniqueAgentCodesResult = await getUniqueAgentCodes({
          masterUid: value,
          groupId: watchGroupId!,
        });
        if (getUniqueAgentCodesResult.status === 'ok') {
          subAgentCode = getUniqueAgentCodesResult.subAgentCode;
        }
      }
      setValue('subAgentCode', subAgentCode);
    } else {
      setValue('payBy', undefined);
      setValue('subAgentCode', '');
      const getUniqueAgentCodesResult = await getUniqueAgentCodes({groupId: watchGroupId!});
      if (getUniqueAgentCodesResult.status === 'ok') {
        setValue('agentCode', getUniqueAgentCodesResult.agentCode);
      }
    }
  };

  useEffect(() => {
    if (agent) {
      const {_group, dateOfBirth, ...agentWithoutGroup} = agent;
      const dateOfBirthAsDate = dateOfBirth?.seconds
        ? new Date(dateOfBirth.seconds * 1000).toLocaleDateString('en-US')
        : null;
      reset({
        ...copyWithoutRef(agentWithoutGroup),
        groupId: agent.group.id,
        dateOfBirth: dateOfBirthAsDate as unknown as UniversalTimestamp,
      });
    }
  }, [agent, reset]);

  const handleAddressAutoFill = (city: any, state: any, zip: any) => {
    const empty = '';
    setValue(`office.address2`, empty, {shouldDirty: true});
    setValue(`office.city`, city ? city : empty, {shouldDirty: true});
    setValue(`office.state`, state ? state : empty, {shouldDirty: true});
    setValue(`office.zipCode`, zip ? zip : empty, {shouldDirty: true});
  };

  const handleMenuItemClick = (agentStatus: AgentStatus) => () => {
    setAgentStatus(agentStatus);
  };

  const {
    fields: agentResidualSharesFields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'agentResidualShares',
  });

  // hack for displaying residual shares array controlled by react-hook-form
  // its empty on first render, until interacted
  useEffect(() => {
    if (getValues) {
      const firstResidualShare = getValues('agentResidualShares')?.[0];
      if (firstResidualShare !== undefined) update(0, firstResidualShare);
    }
  }, [getValues, update]);

  const isReadonly = Boolean(!!agent?.id && !isAuthUserAdmin);

  const agentScheduleA = agentsWithScheduleA.find(x => x.id === agent?.id);

  const filteredOverrideAgents = React.useMemo(
    () =>
      agents?.filter(a => {
        return (
          a.group.id === agent?.group.id &&
          agent.isActive &&
          a.id !== agent.id &&
          agentsWithScheduleA.find(x => x.id === a.id)?.latestScheduleAVersion?.splitMethod !==
            SplitMethodType.direct
        );
      }) || [],
    [agents, agent, agentsWithScheduleA]
  );

  if (groups?.data && groups.promised) {
    return <Loading />;
  }

  return (
    <>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="firstName"
          label="First Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
          autoFocus
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="lastName"
          label="Last Name"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="dateOfBirth"
          label="Date of Birth"
          placeholder="__/__/____"
          errors={errors}
          control={control}
          disabled={isReadonly}
          transform={{
            pattern: '99/99/9999',
          }}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="phone"
          label="Phone Number"
          errors={errors}
          control={control}
          disabled={isReadonly}
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="email"
          label="Email"
          required
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <Select
          name="timeZoneId"
          label="Time Zone"
          errors={errors}
          control={control}
          disabled={isReadonly}
        >
          {Object.entries(UnitedStatesTimeZonesShortList).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <StyledTitleWrapper>
          <StyledTitle>Office</StyledTitle>
          <Divider />
        </StyledTitleWrapper>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="office.name"
          label="Name"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleAddressAutoFill}
          name="office.address"
          label="Address Line 1"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="office.address2"
          label="Address Line 2"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleAddressAutoFill}
          name="office.city"
          label="City"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <Select
          name="office.state"
          label="State"
          errors={errors}
          disabled={isReadonly}
          control={control}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={editMode ? 4 : 12}>
        <TextField
          name="office.zipCode"
          label="Zip Code"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledTitleWrapper>
          <StyledTitle>Admin Contact</StyledTitle>
          <Divider />
        </StyledTitleWrapper>
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="adminContact.name"
          label="Name"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <TextField
          name="adminContact.email"
          label="Email"
          errors={errors}
          control={control}
          disabled={isReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        {groups?.data?.length && (
          <Select
            name="groupId"
            label="Group"
            required
            disabled={!isAuthUserAdmin}
            onChangeSuccess={handleGroupChangeSuccess}
            errors={errors}
            control={control}
          >
            {groups.data &&
              groups.data.sortAndMap(
                group => (
                  <MenuItem key={`${group.id}`} value={group.id}>
                    {group.name}
                  </MenuItem>
                ),
                group => group.name
              )}
          </Select>
        )}
      </Grid>
      <Grid item xs={editMode ? 6 : 12}>
        <Select
          name="masterUid"
          label="Master Agent"
          errors={errors}
          control={control}
          onChangeSuccess={handleMasterUidChangeSuccess}
          disabled={isReadonly || !filteredAgents || filteredAgents.length === 0}
        >
          <MenuItem value={''}>{'None'}</MenuItem>
          {filteredAgents?.sortAndMap(
            a => (
              <MenuItem key={a.id} value={a.id}>
                {a.firstName} {a.lastName}
              </MenuItem>
            ),
            a => `${a.firstName} ${a.lastName}`
          )}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Select
          name="role"
          label="Role"
          required
          disabled={!isAuthUserAdmin}
          errors={errors}
          control={control}
        >
          {Object.values(GroupRole).sortAndMap(role => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={editMode ? (watchMasterUid ? 3 : 6) : watchMasterUid ? 6 : 12}>
        <TextField
          name="agentCode"
          label="Agent Code"
          required
          errors={errors}
          control={control}
          disabled={Boolean(watchMasterUid) || isReadonly}
        />
      </Grid>
      {watchMasterUid && (
        <Grid item xs={editMode ? 3 : 6}>
          <TextField
            name="subAgentCode"
            label="Sub Agent Code"
            required
            errors={errors}
            control={control}
            disabled={!watchMasterUid || isReadonly}
          />
        </Grid>
      )}
      {editMode && (
        <>
          <Grid item xs={6}>
            <TextField
              name="agentStatus"
              value={agentStatus}
              label="Status"
              disabled={!isAuthUserAdmin}
              errors={errors}
              control={control}
              select
            >
              {Object.values(AgentStatus).sortAndMap(status => (
                <MenuItem key={status} value={status} onClick={handleMenuItemClick(status)}>
                  {status}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="routingNumber"
              label="Routing Number"
              errors={errors}
              control={control}
              disabled={isReadonly}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="accountNumber"
              label="Account Number"
              errors={errors}
              control={control}
              disabled={isReadonly}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTitleWrapper>
              <StyledTitle>Permissions</StyledTitle>
              <Divider />
            </StyledTitleWrapper>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListItem
                secondaryAction={
                  <Controller
                    control={control}
                    name="uwNotifyMerchant"
                    render={({field: {onChange, onBlur, value, ref}}) => (
                      <Switch
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value || false}
                        ref={ref}
                      />
                    )}
                  />
                }
              >
                <ListItemText
                  primary="Underwriting Notifications"
                  secondary={
                    <>
                      Enabling this will send Underwriting Notifications related to application
                      statuses directly to the Merchant and Agent.
                      <br />
                      If this feature is disabled, only the Agent will receive Underwriting
                      Notifications.
                    </>
                  }
                />
              </ListItem>
              <ListItem
                secondaryAction={
                  <Controller
                    control={control}
                    name="permissions.residuals"
                    render={({field: {onChange, onBlur, value, ref}}) => (
                      <Switch
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value || false}
                        ref={ref}
                        disabled={isReadonly}
                      />
                    )}
                  />
                }
              >
                <ListItemText
                  primary={'Residuals'}
                  secondary={'Enabling will allow agent to view their residual reports.'}
                />
              </ListItem>
              {isGroupSplitAdditionalServiceFeeDisabled && (
                <ListItem>
                  <ListItemText
                    primary={'Additional Service Fees Revenue Share'}
                    secondary={
                      'Enabling this will calculate the Additional Service Fees at the Agents Revenue Share Percentage'
                    }
                  />

                  <ListItemSecondaryAction>
                    <Controller
                      control={control}
                      name="splitAdditionalServiceFee"
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <Switch
                          onBlur={onBlur}
                          onChange={onChange}
                          checked={value || false}
                          ref={ref}
                          disabled={isReadonly}
                        />
                      )}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )}
              <ListItem>
                <ListItemText
                  primary={'Disable Online Applications'}
                  secondary={
                    'Activating this will disable Agents/Sub Agents from being able to submit Online Applications'
                  }
                />

                <ListItemSecondaryAction>
                  <Controller
                    control={control}
                    name="disableOnlineApplications"
                    render={({field: {onChange, onBlur, value, ref}}) => (
                      <Switch
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value || false}
                        ref={ref}
                        disabled={isReadonly}
                      />
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={'Lock Agent Hierarchy'}
                  secondary={
                    'Group admin will not have access to the Master Agent field in the profile when this is locked'
                  }
                />

                <ListItemSecondaryAction>
                  <Controller
                    control={control}
                    name="isHierarchyLocked"
                    render={({field: {onChange, onBlur, value, ref}}) => (
                      <Switch
                        onBlur={onBlur}
                        onChange={onChange}
                        checked={value || false}
                        ref={ref}
                        disabled={isReadonly}
                      />
                    )}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            <StyledTitleWrapper>
              <StyledTitle>Add Residual Share</StyledTitle>
              <Divider />
            </StyledTitleWrapper>
          </Grid>
          {agentScheduleA?.latestScheduleAVersion?.splitMethod !== SplitMethodType.direct && (
            <Grid item xs={12}>
              <Typography>{`Override is allowed only when agent's Schedule A calculation method is "Direct Schedule A Calculation"`}</Typography>
            </Grid>
          )}
          {agentScheduleA?.latestScheduleAVersion?.splitMethod === SplitMethodType.direct && (
            <>
              {agentResidualSharesFields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <Grid item xs={7}>
                    <Controller
                      control={control}
                      name={`agentResidualShares.${index}.agentId`}
                      render={({field: agentIdField}) => (
                        <>
                          <Autocomplete
                            fullWidth
                            sx={{marginTop: 2}}
                            disabled={!isAllowedEditShares}
                            options={
                              filteredOverrideAgents?.filter(a => {
                                const alreadySelectedIds =
                                  (watch('agentResidualShares') || []).map(item => item.agentId) ||
                                  [];
                                return (
                                  a.id === agentIdField.value || !alreadySelectedIds.includes(a.id)
                                );
                              }) || []
                            }
                            getOptionLabel={getAgentLabel}
                            value={agents?.find(a => a.id === agentIdField.value) || null}
                            onChange={(_, selectedAgent) => {
                              agentIdField.onChange(selectedAgent?.id || '');
                              setValue(
                                `agentResidualShares.${index}.agentName`,
                                selectedAgent ? getAgentLabel(selectedAgent, true) : ''
                              );
                            }}
                            renderInput={params => (
                              <MuiTextField
                                {...params}
                                label="Agents Shares"
                                error={
                                  Boolean(errors?.agentResidualShares?.[index]?.agentId) ||
                                  Boolean(errors?.agentResidualShares?.[index]?.agentName)
                                }
                                helperText={
                                  errors?.agentResidualShares?.[index]?.agentId?.message ||
                                  errors?.agentResidualShares?.[index]?.agentName?.message
                                }
                              />
                            )}
                          />
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      type="number"
                      name={`agentResidualShares.${index}.displayedResiduals`}
                      label="Displayed Residuals %"
                      disabled={!isAllowedEditShares}
                      control={control}
                      errors={errors}
                    />
                  </Grid>
                  {isCalculatedSharesVisible && (
                    <Grid item xs={2}>
                      <TextField
                        type="number"
                        name={`agentResidualShares.${index}.calculatedResiduals`}
                        label="Calculated Residuals %"
                        disabled={!isAllowedEditShares}
                        control={control}
                        errors={errors}
                      />
                    </Grid>
                  )}

                  {isAllowedEditShares && (
                    <Grid item xs={1}>
                      <IconButton color="error" sx={{marginTop: 3}} onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </React.Fragment>
              ))}
              {isAllowedEditShares && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        append({
                          agentId: '',
                          agentName: '',
                          displayedResiduals: 0,
                          calculatedResiduals: 0,
                        })
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="payTo"
              label="Pay To"
              errors={errors}
              control={control}
              disabled={isReadonly || selectedGroupPayTo === PayTo.payToGroup} //editable only when group level has pay agent or pay sub agent, otherwise only viewable
              defaultValue={PayTo.payToGroup}
            >
              <MenuItem key={PayTo.payToGroup} value={PayTo.payToGroup}>
                {PayTo.payToGroup}
              </MenuItem>
              <MenuItem key={PayTo.payToAgent} value={PayTo.payToAgent}>
                {PayTo.payToAgent}
              </MenuItem>
              <MenuItem key={PayTo.payToAgentSubAgent} value={PayTo.payToAgentSubAgent}>
                {PayTo.payToAgentSubAgent}
              </MenuItem>
            </Select>
          </Grid>
          {watchMasterUid && (
            <Grid item xs={12}>
              <Select
                name="payBy"
                label="Pay By"
                errors={errors}
                control={control}
                defaultValue={PayBy.payByMasterAgent}
              >
                <MenuItem key={PayBy.payByMasterAgent} value={PayBy.payByMasterAgent}>
                  {PayBy.payByMasterAgent}
                </MenuItem>
                <MenuItem key={PayBy.payByGroup} value={PayBy.payByGroup}>
                  {PayBy.payByGroup}
                </MenuItem>
              </Select>
            </Grid>
          )}
        </>
      )}

      <Grid item xs={12}>
        <StyledTitleWrapper>
          <StyledTitle>Extra</StyledTitle>
          <Divider />
        </StyledTitleWrapper>
        <Box>
          <Box sx={{fontSize: '1em'}}>
            Site Jump Agents
            <Box sx={{fontSize: '0.8em'}}>
              Agent will be allowed to jump to portal as another agent
            </Box>
          </Box>
          {!isAuthUserAdmin ? (
            <Box sx={{color: 'red'}}>Only admins are allowed to see this section</Box>
          ) : (
            <Autocomplete
              fullWidth
              multiple
              getOptionLabel={getAgentLabel}
              options={agents ?? []}
              sx={{marginTop: 2}}
              value={agents ? agents.filter(a => !!siteJumpAgents.find(x => x.id === a.id)) : []}
              renderInput={params => <MuiTextField {...params} label="Associated Agents" />}
              renderOption={(props, agent: AgentView, {selected}) => (
                <MenuItem {...props} key={agent.id}>
                  <Checkbox checked={selected} />
                  <ListItemText primary={getAgentLabel(agent)} />
                </MenuItem>
              )}
              onChange={(_event, selectedAgents) => {
                setValue(
                  'siteJumpAgents',
                  selectedAgents.map(x => ({
                    id: x.id,
                    fullName: `${x.firstName} ${x.lastName}`,
                    groupName: x.group.name,
                  })),
                  {shouldDirty: true}
                );
              }}
            />
          )}
        </Box>
        <ListItem
          secondaryAction={
            <Controller
              control={control}
              name="isVip"
              render={({field: {onChange, onBlur, value, ref}}) => (
                <Switch
                  onBlur={onBlur}
                  onChange={onChange}
                  checked={value || false}
                  ref={ref}
                  disabled={isReadonly}
                />
              )}
            />
          }
        >
          <ListItemText
            primary={'VIP/Priority'}
            secondary={'Enabling this will add status to agent as VIP/Priority'}
          />
        </ListItem>
      </Grid>
    </>
  );
};
