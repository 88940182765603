import {Box, FormGroup, ListItem, ListItemText, Switch} from '@mui/material';
import {ApplicationType} from '@ozark/functions/src/shared';

import React from 'react';
import {Control, Controller} from 'react-hook-form';

interface ApplicationTypeSelectorProps {
  control: Control<any>;
  name: string;
}

export const ApplicationTypeSelector: React.FC<ApplicationTypeSelectorProps> = ({
  control,
  name,
}) => {
  const applicationTypes = Object.keys(ApplicationType).filter(
    typeKey => typeKey !== 'dualPricing'
  );

  return (
    <Box flex={1}>
      <FormGroup>
        <Controller
          name={name}
          control={control}
          defaultValue={[]}
          render={({field}) => (
            <>
              {applicationTypes.map(typeKey => (
                <ListItem
                  key={typeKey}
                  secondaryAction={
                    <Switch
                      checked={!(field.value || []).includes(typeKey)}
                      onChange={e => {
                        const updatedValue = e.target.checked
                          ? (field.value || []).filter((type: string) => type !== typeKey)
                          : [...(field.value || []), typeKey];
                        field.onChange(updatedValue);
                      }}
                    />
                  }
                >
                  <ListItemText
                    primary={ApplicationType[typeKey as keyof typeof ApplicationType]}
                  />
                </ListItem>
              ))}
            </>
          )}
        />
      </FormGroup>
    </Box>
  );
};
